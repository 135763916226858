//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import Formatter from "@/components/libraries/Formatter";

const formatter = new Formatter();

export default {
  name: "WeekScheduler",
  data() {
    return {
      list: []
    };
  },
  props: {
    default: Array,
  },
  computed: {},
  watch: {
    list: function(){
      this.emitComponent();
    },
    default: function(val){
      this.list = [];
      for (let key in val){
        let obj = {};
        obj.time_from = val[key].time_from;
        obj.time_to = val[key].time_to;
        let weekdays = [];
        for(let i = 0; i <= 6; i++){
          if(val[key].weekdays.includes(i)){
            weekdays[i] = true;
          }else{
            weekdays[i] = false;
          }
        }
        obj.weekdays = weekdays;
        this.list.push(obj);
      }
    }
  },
  methods: {
    emitComponent(){
      let result = [];
      for(let key in this.list){
        let obj = {};
        obj.time_from = this.list[key].time_from;
        obj.time_to = this.list[key].time_to;
        obj.minute_from = formatter.timeToMinute(this.list[key].time_from);
        obj.minute_to = formatter.timeToMinute(this.list[key].time_to);
        let weekdays = [];
        for(let i = 0; i <= 6; i++){
          if(this.list[key].weekdays[i]){
            weekdays[weekdays.length] = i;
          }
        }
        obj.weekdays = weekdays;
        result.push(obj);
      }
      this.$emit('emitComponent', result);
    },
    addSchedule(){
      let obj = {};
      obj.time_from = '';
      obj.time_to = '';
      obj.weekdays = [false,false,false,false,false,false,false];
      this.list.push(obj);
    },
    removeLast(){
      this.list.splice(this.list.length -1, 1);
    },
    toggleWeek(index, weekNumber){
      for(let key in this.list){
        if(this.list[key].weekdays[weekNumber] && +key !== +index){
          this.$store.dispatch("addNotification", {
            text: this.$t('components.weekScheduler.weekBeenSelected'),
            time: 3,
            color: "danger"
          });
          return false;
        }
      }
      this.$set(this.list[index].weekdays, weekNumber, !this.list[index].weekdays[weekNumber]);
      this.emitComponent();
    }
  },
  created() {}
};
