//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//



import axios from "axios";

export default {
  name: "setterMetro",
  data(){
    return {
      cities: [],
      selectedCity: 0,
      selectedStation: 0, // model
      metroLines: [],
    }
  },
  computed: {
    metroStations(){
      let result = [];
      for(let key in this.metroLines){
        if(+this.metroLines[key].city_id === +this.selectedCity){
          for(let keyStation in this.metroLines[key].metroStations){
            result.push(this.metroLines[key].metroStations[keyStation]);
          }
        }
      }
      return result.sort(function(a, b) {
          if(a.station_name[0] < b.station_name[0]) return -1;
          if(a.station_name[0] > b.station_name[0]) return 1;
          return 0;
      });
    }
  },
  props: {
    value: String,
  },
  watch: {
    value: function(val){
      if(val > 0){
        let formData = new FormData();
        formData.append('metro_id', val);

        axios
            .post(`/v1/localization/get-city-by-metro`, formData)
            .then(resp => {
              this.selectedCity = resp.data.city_id;
              this.loadMetroLinesAndSet(this.selectedCity, val);
            })
            .catch(err => {
              this.$store.dispatch("addNotification", {
                text: err.response.data.message,
                time: 6,
                color: "danger"
              });
            });
      }else{
        this.selectedCity = '0';
        this.selectedStation = '0';
      }
    },
    selectedStation: function(val){
      this.$emit('input', val);
    },
    selectedCity: function(val){
      this.getMetroLines(val);
    }
  },
  methods: {
    loadMetroLinesAndSet(city_id, metro_id){
      let formData = new FormData();
      formData.append('city_id', city_id);

      axios
          .post(`/v1/localization/get-metro-lines`, formData)
          .then(resp => {
            this.metroLines = resp.data;

            for(let key in this.metroLines){
              for(let keyStation in this.metroLines[key].metroStations){
                if(+metro_id === +this.metroLines[key].metroStations[keyStation].id){
                  this.selectedStation = this.metroLines[key].metroStations[keyStation].id;
                }
              }
            }
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    getMetroLines(city_id){
      let formData = new FormData();
      formData.append('city_id', city_id);

      axios
          .post(`/v1/localization/get-metro-lines`, formData)
          .then(resp => {
            this.metroLines = resp.data;
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    getCities(){
      axios
          .get(`/v1/localization/get-city`)
          .then(resp => {
            this.cities = resp.data;
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    }
  },
  created() {
    this.getCities();
  },
  beforeDestroy() {
    
  }
};
