//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import DatePicker from "vue2-datepicker";
import Formatter from "@/components/libraries/Formatter";

const formatter = new Formatter();

export default {
  name: "PrinterCustomSchedule",
  data() {
    return {
      list: [],
      pickerLang: {
        formatLocale: {
          firstDayOfWeek: 1,
          months: this.$t('datepicker.months'),
          weekdaysMin: this.$t('datepicker.weekdaysMin'),
          monthsShort: this.$t('datepicker.monthsShort'),
        },
        monthBeforeYear: false,
      },
    };
  },
  components: {DatePicker},
  props: {
    default: Array,
  },
  computed: {},
  watch: {
    list: function(){
      this.emitComponent();
    },
    default: function(val){
      this.list = [];
      for (let key in val){
        let obj = {};
        obj.time_from = val[key].time_from;
        obj.time_to = val[key].time_to;
        obj.daterange = [
          formatter.phpDateToJSObject(val[key].date_from),
          formatter.phpDateToJSObject(val[key].date_to)
        ];

        this.list.push(obj);
      }
    }
  },
  methods: {
    emitComponent(){
      let result = [];
      
      for(let key in this.list){
        let obj = {};
        obj.time_from = this.list[key].time_from;
        obj.time_to = this.list[key].time_to;
        obj.minute_from = formatter.timeToMinute(this.list[key].time_from);
        obj.minute_to = formatter.timeToMinute(this.list[key].time_to);
        if(this.list[key].daterange.length > 0){
          obj.date_from = formatter.jsObjectToPhp(this.list[key].daterange[0]);
          obj.date_to = formatter.jsObjectToPhp(this.list[key].daterange[1]);
        }else{
          obj.date_from = '';
          obj.date_to = ''
        }
        
        result.push(obj);
      }
      this.$emit('emitComponent', result);
    },
    addSchedule(){
      let obj = {};
      obj.time_from = '';
      obj.time_to = '';
      obj.daterange = [];
      this.list.push(obj);
    },
    deleteItem(idx){
      for(let key in this.list){
        if(key == idx){
          this.list.splice(key, 1);
        }
      }
    }
  },
  created() {}
};
